<template>
  <!--费用报销单-->
  <!-- <el-button size="mini" style="position: relative;right: 20px;top: 4px;float: right;z-index: 9;">
            保存
        </el-button> -->

  <el-form>
    <el-col :xs="24" style="position: relative;right: 20px;top: 4px;float: right;z-index: 9;">
      <el-button color="#626aef" plain @click="save" v-if="opts.status == 0">保存草稿 </el-button>
      <el-button type="primary" plain @click="openDialog" v-if="opts.status == 0">提交审核</el-button>

      <el-button type="primary" plain @click="openDialog" v-if="opts.status == 10">审批</el-button>

      <el-button type="success" plain @click="print">打印</el-button>
      <el-button @click="back">取消</el-button>
    </el-col>
  </el-form>
  <el-tabs type="border-card" v-model="tabName">
    <el-tab-pane name="workform">
      <template #label>
        <span> 单据信息 </span>
      </template>
      <div class="workform" ref="printRef">
        <h4 class="form-title">{{ opts.formName }}</h4>
        <el-row class="form-no">
          <el-col :span="8"></el-col>
          <el-col :span="16">编号： {{ opts.billCode || '' }} &nbsp;&nbsp; 日期：{{ opts.createDate }}</el-col>
        </el-row>
        <el-form :model="forms" :rules="rules" ref="forms">
          <table class="form-table">
            <tr style="height:0px;">
              <td style="width:18%;"></td>
              <td></td>
              <td style="width:18%;"></td>
              <td></td>
            </tr>
            <tr>
              <td class="form-label">所属公司</td>
              <td class="form-input">
                <span style="display: none;">{{ user.companyID + "" + user.departmentID }}</span>
                <el-tree-select ref="companys" v-model="forms.companyID" :data="companys" clearable
                  :disabled="opts.status != 0" @change="forms.departmentID = ''; companyChange()" check-strictly
                  :render-after-expand="false" />
              </td>
              <td class="form-label">申请部门</td>
              <td class="form-input">
                <el-tree-select ref="departments" v-model="forms.departmentID" :data="departments"
                  :disabled="opts.status != 0" check-strictly :render-after-expand="false" />
              </td>
            </tr>
            <tr>
              <td class="form-label">原 &nbsp;借 &nbsp;款</td>
              <td class="form-input">
                <el-input-number v-model="forms.originFee" :min="0" :max="100000" controls-position="right"
                  :disabled="opts.status != 0" placeholder="0.00"></el-input-number>
              </td>
              <td class="form-label">应退余款</td>
              <td class="form-input">
                <el-input-number v-model="forms.refundFee" :min="0" :max="100000" controls-position="right"
                  :disabled="opts.status != 0" placeholder="0.00"></el-input-number>
              </td>
            </tr>
            <tr>
              <td class="form-label">银行账户</td>
              <td class="form-input" colspan="3">
                <el-input v-model="forms.bankInfo" type="textarea" autocomplete="off" :disabled="opts.status != 0"
                  placeholder="请输入要报销的银行卡、姓名、号码以及开户行信息" :rows="2"></el-input>
              </td>
            </tr>
            <tr>
              <td class="form-label">备 &nbsp; &nbsp; 注</td>
              <td class="form-input" colspan="3">
                <el-input v-model="forms.desc" type="textarea" autocomplete="off" placeholder="请输入备注信息"
                  :disabled="opts.status != 0" :rows="2"></el-input>
              </td>
            </tr>
            <tr>
              <td class="form-label">合计金额</td>
              <td class="form-input" colspan="3">
                &nbsp;&nbsp;￥ {{ forms.subtotal || "0.00" }} &nbsp;&nbsp;
                {{ bigFeeStr || "零" }}
              </td>
            </tr>

            <tr>
              <td class="form-label form-input" colspan="4">
                <table class="child-table">
                  <tr style="height:0px;">
                    <td style="width:8%;"></td>
                    <td></td>
                    <td style="width:18%;"></td>
                    <td style="width:8%;"></td>
                  </tr>
                  <tr>
                    <td class="form-label">序号</td>
                    <td class="form-label">用 &nbsp;&nbsp;&nbsp;&nbsp; 途</td>
                    <td class="form-input form-center">金额(元)</td>
                    <td class="form-input form-center">操作</td>
                  </tr>
                  <tr v-for="(item, index) in forms.details" :key="item.key">
                    <td class="form-label">{{ index + 1 }}</td>
                    <td class="form-label form-input">
                      <el-input v-model="item.name" :disabled="opts.status != 0"
                        :rules="[{ required: true, message: '请输入报销用途', trigger: 'blur' }]"></el-input>
                    </td>
                    <td class="form-input">
                      <el-input-number v-model="item.chargeFee" :min="0" :max="100000" @change="calcSubtotal"
                        :disabled="opts.status != 0" controls-position="right" placeholder="0.00"
                        :precision="2"></el-input-number>
                    </td>
                    <td class="form-input">
                      <el-button @click="removeItem(index)" :disabled="opts.status != 0">删除</el-button>
                    </td>
                  </tr>
                  <tr>
                    <td class="form-label form-input form-center" colspan="4">
                      <el-button type="primary" @click="forms.details.push({})"
                        :disabled="opts.status != 0">增加明细</el-button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>


            <tr>
              <td class="form-label form-input form-center" colspan="4" style="line-height: 20px;">
                出账银行信息
              </td>
            </tr>
            <tr>
              <td class="form-label">银行名称</td>
              <td class="form-input">
                <el-input v-model="forms.outBankName" placeholder="请输入出账的银行名称" v-if="cashierVisible"
                  :disabled="cashierEnabled"></el-input>
              </td>
              <td class="form-label">银行账户</td>
              <td class="form-input">
                <el-input v-model="forms.outBankAccount" placeholder="请输入出账的银行账户" v-if="cashierVisible"
                  :disabled="cashierEnabled"></el-input>
              </td>
            </tr>
          </table>
        </el-form>
      </div>
    </el-tab-pane>
    <el-tab-pane name="fileform">
      <template #label>
        <span> 表单附件 </span>
      </template>

      <div id="fileform">
        <el-upload
          :action="apiUrl + 'BaseUploader/Basic?dataType=' + opts.formType + '&dataID=' + opts.uniqueCode + '&fileType=form&fileCode=' + opts.billCode"
          list-type="picture-card" :on-success="uploadHandleSuccess" :on-remove="uploadHandleRemove"
          :before-upload="uploadHandleBefore" :file-list="files">
          <el-icon>
            <Plus />
          </el-icon>
        </el-upload>
      </div>
    </el-tab-pane>
    <el-tab-pane name="flownode">
      <template #label>
        <span> 审批记录 </span>
      </template>

      <div id="flownode">
      </div>
    </el-tab-pane>
  </el-tabs>

  <static-dialog ref="flowAuditDialog" :ok="submit" width="650px" class="dialog" title="提交流程">
    <flowAudit ref="flowAudit" :instance="opts"></flowAudit>
  </static-dialog>
</template>
  
<script>
import flowAudit from "@/views/workflow/flowAudit.vue";
import StaticDialog from "@/components/static-dialog.vue";
import { getBillCode, submitInstance, getInstance } from "@/api/workflow";
import { fetchFileList, delFile } from "@/api/basic";
export default {
  name: "reimbursementDetail",
  components: { StaticDialog, flowAudit },
  data() {
    return {
      apiUrl: '',
      tabName: "workform",
      opts: {
        formType: 'FYBX',
        formName: '费用报销单',
        flowCode: 'flow-expense',
        status: 0,
      },
      forms: {
        details: [
          {}
        ]
      },
      rules: {
        // bankInfo: [
        //     { required: true, message: "请输入要报销的银行账户信息", trigger: "blur" },
        // ],
        // desc: [
        //     { required: true, message: "请输入报销的原因及备注", trigger: "blur" },
        // ],
      },
      files: [],

      userInfo: {},
      departments: [],
      companys: [],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    check() {
      this.forms.companyName = this.$refs.companys.getNode(this.forms.companyID).data.companyName;
      this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentID).data.departmentName;

      if (!this.forms.bankInfo) {
        this.$message({ type: "info", message: "请填写补充银行账户信息", });
        return false;
      }
      if (!this.forms.desc) {
        this.$message({ type: "info", message: "请填写补充报销备注信息", });
        return false;
      }
      if (this.forms.details.length == 0) {
        this.$message({ type: "info", message: "请填写至少一条报销明细", });
        return false;
      }
      let valid = true;
      this.forms.details.forEach(item => {
        if (!item.name || item.chargeFee <= 0) {
          valid = false;
        }
      });
      if (!valid) {
        this.$message({ type: "info", message: "请补充完善报销明细信息", });
        return false;
      }
      this.$refs.forms.validate((valid) => {
        if (!valid) return;
      });
      return true;
    },
    save() {
      if (!this.check())
        return;

      this.opts.formUrl = location.pathname;
      this.opts.formData = JSON.stringify(this.forms);
      console.log(this.opts);
      submitInstance(this.opts).then((res) => {
        if (res.code === 200) {
          this.$message({ type: "success", message: res.content, });
          //window.history.back();
          this.$router.push("/workflow/instance");
        }
      });
    },
    submit() {
      let data = this.$refs.flowAudit.getAuditor();
      if ((data.nextNode.clazz != 'end') && data.operator.length == 0) {
        this.$message.error("请选择下节点的审批人");
        return;
      }
      let auditorIds = []; let auditorNames = [];
      data.operator.forEach(p => {
        auditorIds.push(p.id);
        auditorNames.push(p.realName);
      });
      this.opts.operatorID = auditorIds.join(',');
      this.opts.operatorName = auditorNames.join(',');
      this.opts.nodeId = data.nextNode.id;
      this.opts.nodeName = data.nextNode.label;
      this.opts.status = parseInt(data.status + '');

      this.save();
    },
    openDialog() {
      if (!this.check())
        return;

      console.log(this.opts, this.userInfo);
      if (this.opts.operatorID && this.opts.operatorID.indexOf(this.userInfo.userID) == -1) {
        this.$message({ type: 'error', message: '审批人信息不符，当前审批人：' + this.opts.operatorName, });
        return;
      }

      if (this.opts.nodeName && this.opts.nodeName.indexOf('出纳') > -1) {
        if (!this.forms.outBankName || !this.forms.outBankAccount) {
          this.$message({ type: 'info', message: '请填写本次报销出账的银行' });
          return;
        }
      }

      this.$refs.flowAuditDialog.toggle();
    },
    print() {
      let eles = document.querySelectorAll('.el-input');
      for (let i = 0; i < eles.length; i++) {
        let ele = eles[i];
        ele.innerHTML = "<span style='margin-left:10px;'>" + ele.querySelectorAll('input')[0].value + "</span>";
      }
      eles = document.querySelectorAll('.el-textarea');
      for (let i = 0; i < eles.length; i++) {
        let ele = eles[i];
        ele.innerHTML = "<span style='margin-left:10px;'>" + ele.querySelectorAll('textarea')[0].value + "</span>";
      }

      let rmvs = document.querySelectorAll('.el-input-number__decrease');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }
      rmvs = document.querySelectorAll('.el-input-number__increase');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }
      rmvs = document.querySelectorAll('.el-button');
      for (let i = 0; i < rmvs.length; i++) {
        rmvs[i].remove();
      }

      let printHtml = this.$refs.printRef.innerHTML; //获取指定打印区域
      let oldHtml = window.document.body.innerHTML;
      // 构建新网页
      window.document.body.innerHTML = printHtml;
      // 调用打印功能
      window.print();
      window.document.body.innerHTML = oldHtml; //重新给页面内容赋值；

      location.reload();

      return false;
    },
    removeItem(index) {
      this.forms.details.splice(index, 1);
      console.log(this.forms.details)
    },

    uploadHandleBefore(file) {
      if (!this.opts.uniqueCode)
        return false;
    },
    uploadHandleRemove(file, fileList) {
      delFile(file.id).then((res) => {
        this.$message({ type: res.type, message: res.content });
      });
      this.getFileList();
    },
    uploadHandleSuccess(res, file, fileList) {
      this.$message({ type: res.type, message: res.content, });

      console.log(file);
      fileList = [];
      this.getFileList();
      // if (res.code == 200) {
      // } else {
      //   fileList = fileList.pop()
      // }
    },
    getFileList() {
      fetchFileList(this.opts.uniqueCode).then((res) => {
        this.files = res.data;
      });
    },


    companyChange() {
      if (!this.forms.companyID) return;

      var that = this;
      this.$kaung.departments(this.forms.companyID).then((res) => {
        that.departments = res;
        if (this.forms.companyID == this.companyID) {
          this.forms.departmentID = this.userInfo.departmentID;
        }
      });
    },
    calcSubtotal() {
      this.forms.subtotal = 0;
      this.forms.details.forEach(item => {
        this.forms.subtotal += item.chargeFee || 0;
      });
    }
  },
  created() {
    let that = this;
    this.$kaung.companys().then((res) => {
      that.companys = res;
    });

    that.id = this.$route.query.id;
    if (that.id && that.id.length > 0) {
      //加载意见保存过的数据
      getInstance(that.id).then((res) => {
        if (res.code === 200) {
          this.opts = res.data;
          this.forms = JSON.parse(res.data.formData);

          this.getFileList();
        }
      });
    } else {
      this.opts.status = 0;
      getBillCode(this.opts.formType).then((res) => {
        that.opts.billCode = res.data.billCode;
        that.opts.uniqueCode = res.data.uniqueCode;
        
        this.getFileList();
      });

      setInterval(() => {
        that.opts.createDate = that.$util.fmtDate(new Date(), "yyyy-MM-dd HH:mm:ss")
      }, 1000);
    }

    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  watch: {
    // forms: {
    //     handler(newValue, oldValue) {
    //         // newValue.subtotal = 0;
    //         // newValue.details.forEach(item => {
    //         //     newValue.subtotal += item.chargeFee || 0;
    //         // });
    //         //this.calcSubtotal();
    //     },
    //     deep: true
    // },
    userInfo: {
      handler(newValue, oldValue) {
        this.forms.companyID = newValue.companyID;
        this.companyChange();
        this.forms.departmentID = newValue.departmentID;
      },
      deep: true
    },
  },
  computed: {
    bigFeeStr() {
      return this.$util.toBigMoney(this.forms.subtotal || 0);
    },
    // companyID() {
    //     let user = this.$store.state.userInfo;
    //     this.forms.companyID = this.forms.companyID || user.companyID;
    //     this.companyChange();
    // },
    // departmentID() {
    //     let user = this.$store.state.userInfo;
    //     this.forms.departmentID = this.forms.departmentID || user.departmentID;
    // },
    user() {
      let user = this.$store.state.userInfo;
      //console.log('computed user', user)
      this.userInfo = user;
      // this.forms.companyID = user.companyID;
      // this.companyChange();
      return user;
    },
    cashierVisible() {
      return this.opts.status == '50' || this.opts.status == '-50' || (this.opts.status == '10' && (this.opts.nodeName || '').indexOf('出纳') > -1)
    },
    cashierEnabled() {
      return this.opts.status == '50' || this.opts.status == '-50';
    }
  }

};
</script>
  
<style lang="scss" scoped>
.workform {
  max-width: 720px;
  margin: 0px auto;
  padding: 20px 20px;
  color: #000;
  background: #fff;
}

.form-title {
  text-align: center;
  margin: 10px auto;
  padding-bottom: 10px;
  color: #000;
  font-size: 1.8em;
  font-weight: 400;
  border-bottom: 4px double #000;
  max-width: 410px;
  letter-spacing: 25px;
}

.form-no {
  text-align: right;
  margin: 15px 0;
}

form {
  // border: 1px solid #ccc;
}

.form-table,
.child-table {
  width: 100%;
}

.form-label {
  background: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.95em;
  font-weight: 500;
  vertical-align: middle;

  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-input {
  width: auto;
  line-height: 40px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}

.form-input {

  .el-input__wrapper,
  .el-textarea__inner {
    border: none;
    max-width: 100%;
    box-shadow: none;
    padding: 0px 8px;
    padding-top: 3px;
  }

  .el-input {
    width: 100%;
  }

  .el-select {
    vertical-align: revert;
  }
}

.form-center {
  text-align: center;
}


.child-table {
  font-size: 0.9em;
}

.child-table .form-label,
.child-table .form-input {
  line-height: 30px;
  padding: 5px;
}

.el-tabs__content {
  min-height: 80vh;
}

.el-input-number .el-input .el-input__wrapper .el-input__inner {
  text-align: left;
}

.el-input-number__increase,
.el-input-number__decrease {
  display: none;
}
</style>
  